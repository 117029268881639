import { z } from 'zod'

export const UsernameSchema = z
	.string({ required_error: 'Username is required' })
	.min(3, { message: 'Username is too short' })
	.max(20, { message: 'Username is too long' })
	.regex(/^[a-zA-Z0-9_]+$/, {
		message: 'Username can only include letters, numbers, and underscores',
	})
	// users can type the username in any case, but we store it in lowercase
	.transform(value => value.toLowerCase())

export const PasswordSchema = z
	.string({ required_error: 'Password is required' })
	.min(6, { message: 'Password is too short' })
	.max(100, { message: 'Password is too long' })
export const FirstNameSchema = z
	.string({ required_error: 'First name is required' })
	.min(3, { message: 'Name is too short' })
	.max(40, { message: 'Name is too long' })
export const LastNameSchema = z
	.string({ required_error: 'Last name is required' })
	.min(3, { message: 'Name is too short' })
	.max(40, { message: 'Name is too long' })
export const PreferredNameSchema = z.string().optional()
export const EmailSchema = z
	.string({ required_error: 'Email is required' })
	.email({ message: 'Email is invalid' })
	.min(3, { message: 'Email is too short' })
	.max(100, { message: 'Email is too long' })
	// users can type the email in any case, but we store it in lowercase
	.transform(value => value.toLowerCase())
export const HandicapSchema = z.number().min(1).max(54)
export const TshirtSchema = z.string().default('large')
export const GenderSchema = z.string()
export const AdultSchema = z.string()
export const HolesSchema = z.string()
export const GHINSchema = z
	.number({
		required_error: 'GHIN is required',
		invalid_type_error: 'GHIN must be a number',
	})
	.int()
	.refine(n => n >= 10000000 && n <= 99999999, {
		message: 'Must be 8 digits long',
	})
export const PhoneNumberSchema = z
	.string({ required_error: 'Phone number is required' })
	.min(10, { message: 'Must be 10 digits long' })
	.max(10, { message: 'Must be 10 digits long' })
export const PasswordAndConfirmPasswordSchema = z
	.object({ password: PasswordSchema, confirmPassword: PasswordSchema })
	.superRefine(({ confirmPassword, password }, ctx) => {
		if (confirmPassword !== password) {
			ctx.addIssue({
				path: ['confirmPassword'],
				code: 'custom',
				message: 'The passwords must match',
			})
		}
	})

export const ZelleSchema = z.string({
	required_error: 'Zelle phone or email address is required',
})
export const CompanySchema = z.string().optional()
export const OccupationSchema = z.string().optional()
export const FunFactSchema = z.string().optional()
export const ReasonForJoiningSchema = z.string().optional()
export const LinkedInSchema = z.string().optional()
export const TwitterSchema = z.string().optional()
export const InstagramSchema = z.string().optional()
export const TeamSchema = z.string().optional()
export const FlightSchema = z.string().optional()
